import React, { useMemo, useState } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";

import { PageLayout, SEO } from "@components/page-layout";
import { Products } from "@containers/products";
import {
  BlogArticleBrowseCategories,
  BlogArticleCard,
  BlogArticleCategories,
  BlogArticleCategory,
  BlogArticleHeader,
  BlogArticleHeaderFeatured,
  BlogSubscribe,
  FollowICash,
  Pagination,
} from "@brainfinance/icash-component-library";

import defaultImg from '../images/default.png';

const ARTICLES_PER_PAGE = 9;

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { allContentfulBlogArticle, allContentfulBlogCategory } = useStaticQuery(graphql`
    {
      allContentfulBlogArticle {
        pageInfo {
          totalCount
          pageCount
          itemCount
        }
        nodes {
          slug
          categories {
            slug
            name
          }
          publicationDate
          mainImage {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 600, quality: 70)
            description
          }
          h1
          author {
            slug
            name
            picture {
              gatsbyImageData(width: 60, quality: 100)
            }
          }
          article {
            raw
          }
          metaDescription
        }
      }
      allContentfulBlogCategory {
        nodes {
          name
          slug
        }
      }
    }
  `);

  const featuredArticle = allContentfulBlogArticle.nodes[0];
  const currentPagedArticles = allContentfulBlogArticle.nodes.slice(1 + (currentPage - 1) * ARTICLES_PER_PAGE).slice(0, ARTICLES_PER_PAGE);

  const articlesAfterBasis = useMemo(() => {
    return currentPagedArticles.length % 3 === 1
    ? 'after:basis-[calc(66.666667%_-_30px)]'
    : 'after:basis-[calc(33.333333%_-_30px)]';
  }, [currentPagedArticles]);

  return (
    <PageLayout>
      <SEO title="Blog index title" />
      <div className="sm:px-[22px]">
        <BlogArticleHeader className="mt-[88px] max-w-[1320px] mx-auto md:!mt-[66px] sm:!mt-[44px]">
          <BlogArticleHeaderFeatured
            title={featuredArticle.h1}
            description={featuredArticle.metaDescription}
            author={featuredArticle.author?.name}
            authorImgSrc={featuredArticle.author?.picture?.gatsbyImageData.images.fallback?.src || defaultImg}
            date={new Date(featuredArticle.publicationDate)}
            imgSrc={featuredArticle.mainImage?.gatsbyImageData.images.fallback?.src}
            onClick={() => navigate(`/blog/${featuredArticle.slug}/`)}
            onAuthorClick={() => navigate(`/blog/${featuredArticle.author?.slug}/`)}
          >
            <BlogArticleCategories>
              {featuredArticle.categories?.map((category: any) => (
                <BlogArticleCategory
                  key={category?.id}
                  category={category?.name} 
                  onClick={() => navigate(`/blog/${category?.slug}/`)}
                />
              ))}
            </BlogArticleCategories>
          </BlogArticleHeaderFeatured>
        </BlogArticleHeader>
        <div className="flex max-w-[1612px] my-[44px] mx-auto md:px-[11px] md:mx-0 md:mt-0 md:mb-[55px] sm:px-0">
          <FollowICash
            className="mr-[73px] md:hidden"
            onFacebookFollow={() => console.log('share article on facebook')}
            onTwitterFollow={() => console.log('share article on twitter')}
            onLinkedInFollow={() => console.log('share article on linkedin')}
          />
          <div className="flex flex-col mr-[153px] md:mr-0">
            <div className={"flex flex-wrap justify-around mb-[44px] md:mb-[11px] after:content-[''] " + articlesAfterBasis}>
              {currentPagedArticles.map((node: any, ndx: number) => (
                <BlogArticleCard
                  key={ndx}
                  title={node.h1}
                  description={node.metaDescription}
                  author={node.author?.name}
                  authorImgSrc={node.author?.picture?.gatsbyImageData.images.fallback.src || defaultImg}
                  date={new Date(node.publicationDate)}
                  imgSrc={node.mainImage?.gatsbyImageData.images.fallback.src}
                  onClick={() => navigate(`/blog/${node.slug}/`)}
                  onAuthorClick={() => navigate(`/blog/${node.author?.slug}/`)}
                >
                  <BlogArticleCategories>
                    {node.categories?.map((category: any) => (
                      <BlogArticleCategory
                        key={category?.id}
                        category={category?.name}
                        onClick={() => navigate(`/blog/${category?.slug}/`)}
                      />
                    ))}
                  </BlogArticleCategories>
                </BlogArticleCard>
              ))}
            </div>
            {allContentfulBlogArticle.nodes.length > 10 && (
              <div className="mx-auto">
                <Pagination
                  total={allContentfulBlogArticle.nodes.length - 1}
                  currentPage={currentPage}
                  countPerPage={ARTICLES_PER_PAGE}
                  onPageNumberClick={(pageNumber: number) => setCurrentPage(pageNumber)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="mb-[66px] max-w-[880px] mx-auto">
          <BlogArticleBrowseCategories>
            {allContentfulBlogCategory.nodes.map((category: any) => (
              <BlogArticleCategory
                key={category.slug}
                category={category.name}
                hasArrow
                onClick={() => navigate(`/blog/${category.slug}/`)}
              />
            ))}
          </BlogArticleBrowseCategories>
        </div>
        <div className="mb-[44px] mx-auto max-w-[1320px] md:mb-[22px]">
          <BlogSubscribe className="md:mx-[-1rem] md:!w-[calc(100%+2rem)]" handleSubscribe={() => console.log('subscribed!')}>
            <span>{'Subscribe to our '}</span>
            <span className="text-brand-green">newsletter</span>
          </BlogSubscribe>
        </div>
        <div className="mb-[44px] md:mb-[22px]">
          <Products />
        </div>
      </div>
    </PageLayout>
  );
};

export default Blog;
